export const handlePostcode = () => {
    window.addEventListener("blur", (e) => {
        const { target } = e;
        if (target instanceof Element && target.getAttribute("name") === "billing_postcode") {
            target.value = target.value.trim(); // Only trims spaces when the user finishes typing
        }
    }, true); // `true` ensures it works on all inputs (event capturing phase)

    const billingPostcodes = document.querySelectorAll("input[name='billing_postcode']");

    if (billingPostcodes.length) {
        billingPostcodes.forEach(element => {
            element.value = element.value.trim();   
        });
    }
};
