import { handleAddToCart } from './modules/addToCart';
import { handleDataLayer } from './modules/handleDataLayer';
import { handlePostcode } from './modules/handlePostcode';
import { handleWoocommerceToasts } from './modules/handleWoocommerceToasts';
import { handleHeader } from './modules/header';
import headerWcag from './modules/headerWcag';

var documentReady = function () {
    handleHeader();
    handleAddToCart();
    handleWoocommerceToasts();
    handleDataLayer();
    headerWcag();
    handlePostcode();
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
